import React from "react"
import {
  FaFacebookF,
  FaLinkedinIn,
  FaTwitter,
  FaFacebookMessenger,
} from "react-icons/fa"
import { HiOutlineArrowNarrowRight } from "react-icons/hi"
import { BsClock } from "react-icons/bs"
import {
  FacebookShareButton,
  FacebookMessengerShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share"

import { Box, Button, IconButton, Typography } from "@mui/material"
import { useTheme } from "@emotion/react"

import { Link, graphql } from "gatsby"
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image"

import "swiper/css"
import "swiper/css/pagination"

import Layout from "../../components/layout"
import ReactMarkdown from "react-markdown"
import FeaturedResources from "../../components/featured-resources"

const Hero = ({ title, subtitle, image }) => {
  return (
    <Box
      position="relative"
      borderRadius={3}
      sx={{
        ".gatsby-image-wrapper, img": {
          borderRadius: 3,
        },
      }}
    >
      <GatsbyImage image={getImage(image.localFile)} alt={title} />
      <Box
        borderRadius={3}
        position="absolute"
        top={0}
        width={1}
        height={1}
        sx={{
          background:
            "linear-gradient(89.92deg, rgba(10, 9, 9, 0.64) 0.06%, rgba(10, 9, 9, 0.7) 0.07%, rgba(10, 9, 9, 0.25) 49.99%, rgba(10, 9, 9, 2.41723e-08) 99.92%);",
        }}
      />
      <Box
        position="absolute"
        bottom={0}
        px={{ xs: 2, lg: 5 }}
        py={{ xs: 4, lg: 8 }}
        maxWidth={{ xs: 1, lg: 0.6 }}
      >
        <Typography variant="h1" color="white">
          {title}
        </Typography>
        <Typography
          variant="body1"
          color="white"
          fontWeight={500}
          sx={{ display: { xs: "none", sm: "block" } }}
        >
          {subtitle}
        </Typography>
      </Box>
    </Box>
  )
}
const SocialMediaLinks = ({ display, href }) => {
  const socialMediaLinks = [
    { SocialMediaButton: FacebookShareButton, Icon: FaFacebookF },
    { SocialMediaButton: LinkedinShareButton, Icon: FaLinkedinIn },
    { SocialMediaButton: TwitterShareButton, Icon: FaTwitter },
    {
      SocialMediaButton: FacebookMessengerShareButton,
      Icon: FaFacebookMessenger,
    },
  ]

  return (
    <Box display={display} position={{ lg: "absolute" }} left={-80} height={1}>
      <Box
        display="flex"
        flexDirection={{ xs: "row", lg: "column" }}
        gap={2}
        position={{ lg: "sticky" }}
        top={80}
      >
        {socialMediaLinks.map(({ SocialMediaButton, Icon }, index) => (
          <SocialMediaButton key={index} url={href}>
            <IconButton
              aria-label="Social media link"
              sx={({ palette }) => ({
                bgcolor: palette.primary.light,
                display: "flex",
                alignItems: "center",
                width: { xs: "2rem", sm: "2.5rem" },
                height: { xs: "2rem", sm: "2.5rem" },

                svg: {
                  width: { xs: "12px", md: "18px" },
                  height: { xs: "12px", md: "18px" },
                  color: palette.primary.main,
                },
              })}
            >
              <Icon />
            </IconButton>
          </SocialMediaButton>
        ))}
      </Box>
    </Box>
  )
}
const CaseStudyInfo = ({ readTime, publishedAt, href }) => {
  const { palette } = useTheme()

  return (
    <Box display="flex" justifyContent="space-between" flexWrap="wrap" gap={2}>
      <Box display="flex" alignItems="center">
        <BsClock size={32} strokeWidth={0.1} color={palette.text.secondary} />

        <Box display="flex" flexWrap="wrap" gap={1.5} ml={2}>
          <Typography variant="body2" fontWeight={900}>
            {readTime} min read
          </Typography>
          <Typography variant="body2" fontWeight={900}>
            &bull;
          </Typography>
          <Typography variant="body2" fontWeight={900}>
            {publishedAt}
          </Typography>
        </Box>
      </Box>

      <SocialMediaLinks display={{ xs: "block", lg: "none" }} href={href} />
    </Box>
  )
}
const CaseStudySection = ({ section, images, sx = {} }) => {
  const getStyling = ({ palette }) => ({
    "& > *": {
      m: 0,
    },
    " & > h1, h2, h3, h4, h5, h6": {
      mb: 2,
    },
    " & > p:not(:last-child)": {
      mb: "calc(1.5 * 1.375rem)",
    },
    "& > h1": {
      color: palette.text.primary,
      fontWeight: 600,
      fontSize: { xs: "1.3462rem", sm: "2.5rem", lg: "2.75rem" },
      lineHeight: 1.3,
    },
    "& > h2": {
      color: palette.text.primary,
      fontWeight: 600,
      fontSize: { xs: "1.3462rem", sm: "2.3077rem", lg: "2.5rem" },
      lineHeight: 1.3,
    },
    "& > h3": {
      color: palette.text.primary,
      fontWeight: 600,
      fontSize: { xs: "1.3462rem", sm: "1.9231rem", lg: "2.25rem" },
      lineHeight: 1.3,
    },
    "& > h4": {
      color: palette.text.primary,
      fontWeight: 600,
      fontSize: { xs: "1.25rem", sm: "1.7875", lg: "1.9643rem" },
      lineHeight: 1.4,
    },
    "& > h5": {
      color: palette.text.primary,
      fontWeight: 600,
      fontSize: { xs: "1.25rem", sm: "1.6071rem", lg: "1.75rem" },
      lineHeight: 1.4,
    },
    "& > h6": {
      color: palette.text.primary,
      fontWeight: 400,
      fontSize: { xs: "1rem", lg: "1.125rem" },
      lineHeight: 1.3,
    },
    "& > p, li": {
      color: palette.text.secondary,
      fontSize: { xs: "1rem", sm: "1.1667rem", md: "1.333rem" },
      fontWeight: 400,
      lineHeight: 1.5,
    },
    ul: {
      mt: "calc(-1.5 * 1.125rem) !important",
    },
  })

  return (
    <Box display="flex" flexDirection="column" gap={8} sx={{ ...sx }}>
      <Box sx={theme => ({ ...getStyling(theme) })}>
        <ReactMarkdown>{section}</ReactMarkdown>
      </Box>

      {images &&
        images.map(({ imageHeader, image: { localFile } }, index) => (
          <Box
            key={index}
            display="flex"
            flexDirection="column"
            gap={1}
            alignItems="center"
            sx={{ img: { borderRadius: 2 } }}
          >
            <GatsbyImage image={getImage(localFile)} alt={imageHeader} />
            <Typography
              variant="body3"
              color="primary.darker"
              fontWeight="bold"
            >
              {imageHeader}
            </Typography>
          </Box>
        ))}
    </Box>
  )
}
export const ContactCard = () => {
  return (
    <Box
      sx={{
        a: { textDecoration: "none !important" },
      }}
    >
      <Link to="/contact">
        <Box
          borderRadius={2}
          py={{ xs: 2, md: 4 }}
          px={{ xs: 2, md: 5 }}
          bgcolor="primary.light"
          position="relative"
          overflow="hidden"
          maxWidth={1}
        >
          <Box maxWidth={{ xs: 1, sm: 0.8 }}>
            <Typography variant={"h5"} mb={3}>
              Interested To Find Out More?
            </Typography>

            <Button
              aria-label="Contact us"
              variant="contained"
              endIcon={<HiOutlineArrowNarrowRight size={30} />}
              sx={{
                padding: { xs: "14.5px, 24px", sm: "14.5px 32px" },
                fontSize: "1rem",
                svg: {
                  display: { xs: "none", sm: "inline-block" },
                  ml: 0.5,
                  mb: -0.5,
                },
              }}
            >
              Contact us
            </Button>
          </Box>

          {new Array(5).fill(0).map((e, i) => (
            <Box
              key={e + i}
              border="1px solid"
              borderRadius="50%"
              borderColor="primary.lighter"
              position="absolute"
              bottom={{
                xs: `calc(-80px + ${i * 10}px)`,
                sm: `calc(-100% + ${i * 12.5}%)`,
              }}
              right={{
                xs: `calc(-80px + ${i * 10}px)`,
                sm: `calc(-27.5% + ${i * 3.33}%)`,
              }}
              height={{
                xs: `calc(160px - ${i * 20}px)`,
                sm: `calc(200% - ${i * 25}%)`,
              }}
              sx={{
                aspectRatio: "1",
              }}
            />
          ))}
        </Box>
      </Link>
    </Box>
  )
}
const Quote = ({ text, author, aboutAuthor }) => {
  return (
    <Box
      bgcolor="primary.light"
      width="100vw"
      ml={{
        xs: `calc((-100vw + 100%) / 2)`,
        lg: "calc((-100vw + 795px) / 2)",
      }}
      pt={{ xs: 5, lg: 10 }}
      pb={{ xs: 7, lg: 14 }}
      zIndex={10}
    >
      <Box position="relative" maxWidth={835} mx="auto" px={2.5}>
        <Box
          sx={{
            position: { xl: "absolute" },
            left: { xl: -120 },
          }}
        >
          <StaticImage
            src="../../images/quote.svg"
            alt="quote image"
            placeholder="tracedSVG"
          />
        </Box>
        <Typography variant="h3" mb={5.5} pt={{ xs: 2, xl: 0 }}>
          {text}&quot;
        </Typography>
        <Typography variant="h5" sx={{ textTransform: "uppercase" }} mb={1.5}>
          {" "}
          {author}
        </Typography>
        <Typography
          variant="body1"
          sx={{ textTransform: "capitalize", width: { xs: 1, lg: 0.75 } }}
        >
          {aboutAuthor}
        </Typography>
      </Box>
    </Box>
  )
}

const CaseStudy = ({
  data: {
    strapiCaseStudy: {
      header,
      description: {
        data: { description },
      },
      readTime,
      publishedAt,
      sections,
    },
    allStrapiCaseStudy: { nodes: featuredCaseStudies },
  },
  location: { href },
}) => {
  return (
    <Layout>
      <Box
        height="100%"
        display="flex"
        flexDirection="column"
        gap={{ xs: 2, md: 5 }}
        maxWidth={835}
        mx="auto"
      >
        <Hero {...header} />

        <CaseStudyInfo
          readTime={readTime}
          publishedAt={publishedAt}
          href={href}
        />

        <Box
          position="relative"
          display="flex"
          flexDirection="column"
          gap={{ xs: 5, md: 10 }}
          px={{ xs: 0, lg: 2.5 }}
        >
          <SocialMediaLinks display={{ xs: "none", lg: "block" }} href={href} />

          <CaseStudySection section={description} />

          <ContactCard />

          <Box display="flex" flexDirection="column" gap={{ xs: 5, md: 8 }}>
            {sections.map(
              (
                {
                  images,
                  quote,
                  section: {
                    data: { section },
                  },
                },
                index
              ) => (
                <>
                  <CaseStudySection
                    key={index}
                    section={section}
                    images={images}
                  />
                  {quote && <Quote {...quote} />}
                </>
              )
            )}
          </Box>
        </Box>
      </Box>

      <FeaturedResources
        resourceURI="/case-studies"
        overline="Case studies"
        title="Featured resources"
        resources={featuredCaseStudies}
      />
    </Layout>
  )
}

export const query = graphql`
  query strapiCaseStudy($strapi_id: Int) {
    strapiCaseStudy(strapi_id: { eq: $strapi_id }) {
      strapi_id
      readTime
      publishedAt(formatString: "DD MMM YYYY")
      header {
        title
        subtitle
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      description {
        data {
          description
        }
      }
      sections {
        section {
          data {
            section
          }
        }
        images {
          imageHeader
          image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        quote {
          aboutAuthor
          author
          text
        }
      }
    }

    allStrapiCaseStudy(sort: { fields: publishedAt, order: DESC }, limit: 3) {
      nodes {
        strapi_id
        readTime
        publishedAt
        header {
          title
          subtitle
          image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`

export default CaseStudy
